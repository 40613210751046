import React, { useState, useEffect } from 'react';
import { addDoc, collection, onSnapshot } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { firestore } from '../firebaseConfig';
import { v4 as uuidv4 } from 'uuid';
import '../styles/ProductUploadForm.css';

const ProductUploadForm = () => {
  const [formData, setFormData] = useState({
    name_en: '',
    name_ar: '',
    description_en: '',
    description_ar: '',
    price: '',
    category: '',
    subcategory: '',
    image: null,
  });
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [filteredSubcategories, setFilteredSubcategories] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isPriceEnabled, setIsPriceEnabled] = useState(true); // State to toggle price input

  useEffect(() => {
    const unsubscribeCategories = onSnapshot(collection(firestore, 'categories'), (snapshot) => {
      const categoriesList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCategories(categoriesList);
    });

    const unsubscribeSubcategories = onSnapshot(collection(firestore, 'subcategories'), (snapshot) => {
      const subcategoriesList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setSubcategories(subcategoriesList);
    });

    return () => {
      unsubscribeCategories();
      unsubscribeSubcategories();
    };
  }, []);

  useEffect(() => {
    if (formData.category) {
      const filtered = subcategories.filter((subcategory) => subcategory.category === formData.category);
      setFilteredSubcategories(filtered);
    } else {
      setFilteredSubcategories([]);
    }
  }, [formData.category, subcategories]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'image' && files.length) {
      setFormData({ ...formData, image: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const fetchThumbnailURL = async (fileName, retryCount = 5) => {
    const storage = getStorage();
    const thumbnailRef = ref(storage, `product_images/${fileName}_200x200`);

    for (let i = 0; i < retryCount; i++) {
      try {
        const thumbnailURL = await getDownloadURL(thumbnailRef);
        return thumbnailURL;
      } catch (error) {
        if (error.code === 'storage/object-not-found' && i < retryCount - 1) {
          await new Promise((resolve) => setTimeout(resolve, 2000)); // Wait 2 seconds before retrying
        } else {
          throw error;
        }
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsUploading(true);

    try {
      let imageURL = '';
      let thumbnailURL = '';

      if (formData.image) {
        const storage = getStorage();
        const uniqueName = `${uuidv4()}_${formData.image.name}`;
        const storageRef = ref(storage, `product_images/${uniqueName}`);
        const snapshot = await uploadBytes(storageRef, formData.image);

        // Get the URL of the original image
        imageURL = await getDownloadURL(snapshot.ref);

        // Try to fetch the resized thumbnail URL with retries
        try {
          thumbnailURL = await fetchThumbnailURL(uniqueName);
        } catch (error) {
          console.warn("Thumbnail not available yet. Please check later.");
        }
      }

      const productsCollectionRef = collection(firestore, 'menuItems');
      await addDoc(productsCollectionRef, {
        ...formData,
        price: isPriceEnabled ? parseFloat(formData.price) : null, // Handle price based on checkbox
        image: imageURL,
        thumbnail: thumbnailURL || null,
      });
      alert('Product uploaded successfully!');

      // Reset the form
      setFormData({
        name_en: '',
        name_ar: '',
        description_en: '',
        description_ar: '',
        price: '',
        category: '',
        subcategory: '',
        image: null,
      });
      setIsPriceEnabled(true); // Reset checkbox
    } catch (error) {
      console.error("Error uploading product: ", error);
      alert('Error uploading product. Please try again.');
    } finally {
      setIsUploading(false);
    }
  };

  const togglePriceInput = () => {
    setIsPriceEnabled(!isPriceEnabled);
    if (!isPriceEnabled) {
      setFormData({ ...formData, price: '' }); // Clear price if disabled
    }
  };

  return (
    <div className="product-upload-container">
      <h2 className="form-title">Upload New Product</h2>
      <form onSubmit={handleSubmit} className="product-upload-form">
        <div className="form-group">
          <input type="text" name="name_en" placeholder="Name in English" value={formData.name_en} onChange={handleChange} />
        </div>
        <div className="form-group">
          <input type="text" name="name_ar" placeholder="اسم بالعربي" value={formData.name_ar} onChange={handleChange} />
        </div>
        <div className="form-group">
          <textarea name="description_en" placeholder="Description in English" value={formData.description_en} onChange={handleChange} />
        </div>
        <div className="form-group">
          <textarea name="description_ar" placeholder="وصف بالعربي" value={formData.description_ar} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>
            <input type="checkbox" checked={isPriceEnabled} onChange={togglePriceInput} /> Add Price
          </label>
          <input
            type="number"
            name="price"
            placeholder="Price"
            value={formData.price}
            onChange={handleChange}
            disabled={!isPriceEnabled} // Disable input if checkbox is unchecked
          />
        </div>
        <div className="form-group">
          <select name="category" value={formData.category} onChange={handleChange} required>
            <option value="">Select Category</option>
            {categories.map((category) => (
              <option key={category.id} value={category.name}>{category.name}</option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <select name="subcategory" value={formData.subcategory} onChange={handleChange}>
            <option value="">Select Subcategory</option>
            {filteredSubcategories.map((subcategory) => (
              <option key={subcategory.id} value={subcategory.name}>{subcategory.name}</option>
            ))}
          </select>
        </div>
        <div className="form-group file-input">
          <input type="file" name="image" onChange={handleChange} />
        </div>
        <button type="submit" className="upload-button" disabled={isUploading}>
          {isUploading ? 'Uploading...' : 'Upload Product'}
        </button>
      </form>
    </div>
  );
};

export default ProductUploadForm;
